import { FC } from 'react'
import { Result, Button } from 'antd'
import { Link } from 'gatsby'

import Layout from '@components/Layout'
import SEO from '@components/SEO'

const NotFoundPage: FC = () => (
  <Layout>
    <SEO title="404 Page Not found" />

    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="link">Back Home</Button>
        </Link>
      }
    />
  </Layout>
)

export default NotFoundPage
